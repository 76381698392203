
import router from '@/router'
import { defineComponent, onMounted,ref } from 'vue';
import { notification } from 'ant-design-vue';
import Constains from '@/components/Constains';
import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import * as cGridAll from "vue-cheetah-grid";
import { listItemMetaProps } from 'ant-design-vue/lib/list/ItemMeta';

export default defineComponent({
  components: {
    ...cGridAll,
  },

  setup() {
    const cookies  = useCookies();
    const data:any = ref([]);
    const loading = ref(false)
    const current = ref(1)
    const isAuthority = ref(false)
    const visible = ref(false)
    const editData:any = ref({
      id:0,
      point:null,
      qr:null,
    })
    
    const columns = [
      {
        headeName:'登録日時',
        field:'time',
        title:'登録日時',
        dataIndex:'time',
        prop:'time',
        name:'登録日時'
      },
      {
        headeName:'場所',
        field:'point',
        title:'場所',
        dataIndex:'point',
        prop:'point',
        name:'場所'
      },
      {
        headeName:'登録コード',
        field:'value',
        title:'登録コード',
        dataIndex:'value',
        prop:'value',
        name:'登録コード'
      },
      {
        dataIndex:'operation',
      }
    ]
    onMounted(async() => {
      console.log("onmount");
      loading.value = true;
      await _reload();
      loading.value = false;
    });


    const select = (e:any) => {
      console.log(e)
      e.used = true;
      navigator.clipboard.writeText(e.title)
      notification.open({
        message: `${e.title}をコピーしました`,
        //description:'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        placement:'bottomRight',
      });
      _reload()
    } 

    const _reload = async () => {
      try{
          var token = cookies.cookies.get('token');

          if(token == null){
          if(Constains.autoLogout){
            //ログインページに飛ばす
            router.push('/login');
            return;
            }
          }

                //ログインユーザー情報を取得
          try{
            let res = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token,{headers:{
                'Authorization' :'Bearer '+token, }});
            if(res.status == 200){
              let result = res.data;
              if(result.activate == 0) {
                if(Constains.autoLogout){
                  notification.error({
                    message: '非アクティブなアカウントです.',
                  })
                  setTimeout((()=>{
                    router.push('login')
                    return;
                  }),3000)
                }
              }
              isAuthority.value = result.authority == 1
            } else if(res.status==400) {
              //ログアウトしてログイン画面に
              if(Constains.autoLogout){
                cookies.cookies.remove('token');
                  notification.error({
                    message: '無効なアカウントです.',
                  })
                  setTimeout((()=>{
                    router.push('login')
                    return;
                  }),3000)
                }
            }
            } catch (e) {
              notification.error({
                    message: 'ログインに失敗しました.',
                  })
            if(Constains.autoLogout) return
            }

          const res = await axios.get(Constains.tracerbaseURL+'/api/QRDatas',{headers:{
              'Authorization' :'Bearer '+token,
            }});
          if(res.status == 200){
            let results = res.data;
            data.value = []
            for(let i = 0; i < results.length; i++){
              let result = results[i];
              const date = new Date(result['input_dt'])
              date.setTime(date.getTime() + 1000*60*60*9); //UTCからJSTに変換する
              data.value.push({
                id:result['id'],
                value:result['qr'],
                point:result['point'],
                time: date.toLocaleDateString()+" "+date.toLocaleTimeString(),
              });
            }
          } else {
            //tokenが無効化されているのでログインページに飛ばす
            router.push('/login');
            return;
          }
        } catch(e){
          console.log("editSaveError:",e)
        }
    }

    const copy = () => {
      document.execCommand('copy')
      notification.open({
        message: `コピーしました`,
        //description:'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        placement:'bottomRight',
      });
    }

    const change = (page:number,pageSize:any) => {
      console.log(page)
    }

    const reader = () => {
      router.push('/qrreader');
//      const url = '/gokouScheduler/html/QRReader.html'
//        window.location.href = url
    }

    const edit = (record:any) => {
      console.log(record)
      //editData.value = record
      editData.value.id = record.id
      editData.value.value = record.value
      editData.value.point = record.point
      visible.value = true
    }

    const Ok = async() => {
      var token = cookies.cookies.get('token');
      const json = {
        id:editData.value.id,
        qr:editData.value.value,
        point:editData.value.point,
      }

      await axios.put(Constains.tracerbaseURL+'/api/QRDatas/'+editData.value.id,JSON.stringify(json),
        {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
      
      visible.value = false
      await _reload();
    }

    const cancel = () => {
      visible.value = false;
    }

    const Delete = async() =>{
      var token = cookies.cookies.get('token');
      await axios.delete(Constains.tracerbaseURL+'/api/QRDatas/'+editData.value.id,
        {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
      
      visible.value = false
      await _reload();
    }

    const bulkView = () => {
      console.log("bulkView")
      bulk.value = null
      bulkvisible.value = true
    }

    const bulkvisible = ref(false)
    const bulk:any = ref("")

    const BulkDelete = async() => {
      var token = cookies.cookies.get('token');
      await axios.delete(Constains.tracerbaseURL+'/api/QRClassificationModels?classification='+bulk.value,
        {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
      
      bulkvisible.value = false
      await _reload();
    }

    return {
      select,
      loading,
      columns,
      copy,
      current,
      change,
      data,
      reader,
      edit,
      isAuthority,
      visible,
      editData,
      Ok,
      cancel,
      Delete,
      BulkDelete,
      bulkvisible,
      bulk,
      bulkView,
    };
  },
});
